<template>
  <div class="company">
    <!--INTRO-->
    <div class="company__flat">
      <div>
        <!-- <h2 class="company__title">{{ $t('cHeader4') }}</h2> -->
        <h1 class="company__introtitle">{{ $t('cTitle') }}</h1>
        <div class="company__intro">
          <p>{{ $t('cParagraph41') }}</p>
          <p>{{ $t('cParagraph42') }}</p>
        </div>
      </div>
    </div>

    <!--DESCRIPTION-->
    <div class="company__flat company__flat--withoutBG">
      <div>
        <h2 class="company__title">{{ $t('cMainHeader') }}</h2>
        <div class="company__intro">
          {{ $t('cIntro') }}
        </div>
        <div class="company__descbox">
          <div>
            <div class="company__descbox-title">{{ $t('cHeader1') }}</div>
            <p>{{ $t('cParagraph1') }}</p>
          </div>
          <div>
            <div class="company__descbox-title">{{ $t('cHeader2') }}</div>
            <p>{{ $t('cParagraph2') }}</p>
          </div>
          <div>
            <div class="company__descbox-title">{{ $t('cHeader3') }}</div>
            <p> {{ $t('cParagraph3') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!--PEOPLE-->
    <div class="company__flat company__flat--inverseBG">
      <div>
        <h2 class="company__title">{{ $t('cHeader5') }}</h2>
        <div class="company__intro">
          <p>{{ $t('cParagraph51') }}</p>
          <p>{{ $t('cParagraph52') }}</p>
        </div>

        <!--founder-->
        <div class="company__people">
          <h2 class="company__people-title">{{ $t('cTeam') }}</h2>
          <ul class="company__people-list">
            <template v-for="(people, i) in $t('team')" >
              <li v-if="people.founder" :key="i">
                <neo-people :value="people" />
              </li>
            </template>
          </ul>
        </div>

        <!--Advisors-->
        <div class="company__people">
          <h2 class="company__people-title">{{ $t('cAdvisors') }}</h2>
          <ul class="company__people-list">
            <template v-for="(people, i) in $t('team')" >
              <li v-if="!people.founder" :key="i">
                <neo-people :value="people" />
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import NeoPeople from "@/components/Molecules/NeoPeople";
export default {
  name: 'company-page',
  components: {
    NeoPeople
  },
};
</script>

<style lang="scss" scoped>
.company {
  height: 100%;
  width: 100%;

  &__flat {
    --flat-padding: 80px;
    padding-top: 32px;
    padding-bottom: var(--flat-padding);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='4000' height='120' preserveAspectRatio='none' viewBox='0 0 4000 120'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1102%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c34 C 160%2c44.4 480%2c91.8 800%2c86 C 1120%2c80.2 1280%2c5 1600%2c5 C 1920%2c5 2080%2c84.8 2400%2c86 C 2720%2c87.2 2880%2c8.2 3200%2c11 C 3520%2c13.8 3840%2c82.2 4000%2c100L4000 120L0 120z' fill='rgba(242%2c 198%2c 2%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1102'%3e%3crect width='4000' height='120' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    @media only screen and (min-width: 960px) {
      padding-bottom: calc(var(--flat-padding)*2);
    }
    &--withoutBG {
     background-image: none;
     background-color: var(--color-primary);
     padding-bottom: 32px;
    }
    &--inverseBG {
      padding-top: var(--flat-padding);
      @media only screen and (min-width: 960px) {
        padding-top: calc(var(--flat-padding)*2);
      }
      padding-bottom: 32px;
      background-position: top center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='4000' height='120' preserveAspectRatio='none' viewBox='0 0 4000 120'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1010%26quot%3b)' fill='none'%3e%3crect width='4000' height='120' x='0' y='0' fill='rgba(242%2c 198%2c 2%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c34 C 160%2c41 480%2c66.6 800%2c69 C 1120%2c71.4 1280%2c36.8 1600%2c46 C 1920%2c55.2 2080%2c121.4 2400%2c115 C 2720%2c108.6 2880%2c19.2 3200%2c14 C 3520%2c8.8 3840%2c74 4000%2c89L4000 120L0 120z' fill='rgba(227%2c 227%2c 227%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1010'%3e%3crect width='4000' height='120' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    }
    > div:first-child {
      width: var(--main-width-container);
      margin: auto;
    }
  }

  &__introtitle {
    text-align: center;
    line-height: 1.2;
    font-size: var(--font-size-2);
    margin-bottom: 60px;
  }

  &__title {
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-3);
    margin-bottom: 48px;
  }

  &__intro {
    font-size: var(--font-size-4);
    max-width: 1200px;
    margin: 0 auto 0;
    font-weight: 300;
  }

  &__descbox {
    max-width: 1600px;
    margin: 96px auto 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
    @media only screen and (min-width: 960px) {
      grid-template-columns: repeat(3, 1fr);
    }
    p {
      font-size: var(--font-base-size);
    }
  }

  &__descbox-title {
    font-size: var(--font-size-4);
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 16px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      top: -20px;
      left: 0;
      height: 10px;
      width: 80px;
      background-color: black;
    }
  }

  &__people {
    max-width: 1200px;
    margin: 24px auto 60px;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 960px) {
      grid-template-columns: 150px 1fr;
      gap: 48px;
    }
  }
  &__people-title {
    position: relative;
    margin-top: 20px;
    &:before {
      position: absolute;
      content: '';
      top: -20px;
      left: 0;
      height: 10px;
      width: 80px;
      background-color: black;
    }
  }
  &__people-list {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (min-width: 960px) {
      justify-content: start;
      padding-left: 16px;
      border-left: 1px solid  var(--color-white);
      box-shadow: -1px 0px var(--color-creme);
    }
    li {
      margin: 0 12px;
    }
  }
}
</style>
