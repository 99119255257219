<template>
  <tippy class="frames-tippy" arrow arrow-type="round" theme="dark">
    <template v-slot:trigger>
      <div class="neo-people">
        <div class="neo-people__figure">
          <img
            v-if="value.imageUrl"
            class="neo-people__img"
            :src="getImgUrl(value.imageUrl)"
            :alt="value.name"
          />
          <img
            v-else-if="value.female"
            class="neo-people__img"
            src="@/assets/company/female.svg"
            :alt="value.name"
          />
          <img
            v-else
            class="neo-people__img"
            src="@/assets/company/male.svg"
            :alt="value.name"
          />
          <ul v-if="value.social" class="neo-people__social">
            <li
              v-for="(social, name) in value.social"
              :key="name"
              class="neo-people__social--item"
            >
              <a :href="value.social[name]" target="_blank">
                <v-icon color="primary">{{ `mdi-${name}` }}</v-icon>
              </a>
            </li>
          </ul>
        </div>
        <div class="neo-people__name">
          <div>{{ value.name }}</div>
          <div>{{ value.position }}</div>
        </div>
      </div>
    </template>
    <div>{{ value.desc }}</div>
  </tippy>
</template>

<script>

export default {
  name: "NeoPeople",
  props: {
    value: {
      type: Object,
      required: true,
    },
    src: String,
  },
  data() {
    return {
    };
  },
  methods: {
    getImgUrl(name) {
      const images = require.context('@/assets/company/', false, /\.jpg$/)
      return images('./' + name + ".jpg")
    }
  },
  computed: {
    loadImage() {
      return this.src;
    },
    image() {
      if (this.value.url) {
        return this.value.url;
      }
      return "@/assets/company/female.svg";
    },
  },
  beforeMount() {
    this.url = this.src;
  },
};
</script>

<style lang="scss" scoped>
.neo-people {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__figure {
    flex-grow: 0;
    width: 180px;
    height: 180px;
    position: relative;
  }
  &__img {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 10px solid var(--color-primary);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__name {
    transform: translate(0, -24px);
    padding: 4px 8px;
    background-color: var(--color-primary);
    border-radius: 4px;
    text-align: center;
    div:first-child {
      font-weight: 600;
    }
  }
  &__social {
    li {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: var(--color-white);
      display: flex;
      border: 1px solid var(--color-primary);
      position: absolute;
      bottom: 32px;
      right: 0;
      z-index: 1;
      &:nth-child(2) {
        bottom: 68px;
        right: -12px;
      }
      &:nth-child(3) {
        bottom: 104px;
        right: -8px;
      }
    }
    a {
      display: block;
      margin: auto;
    }
  }
}
</style>
